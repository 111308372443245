import * as React from 'react';
import * as styles from './faq.module.css';

import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Base/Layout';
import { SEO } from '../components/Base/Seo';
import {Text} from '@chakra-ui/react';

const FAQ: FunctionComponent = () => {
	const FAQSchema = {
		'@context': 'https://schema.org',
		'@type': 'FAQPage',
		mainEntity: [
			{
				'@type': 'Question',
				name: 'What are your customer service hours?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'You can reach a customer service representative at DoctorMedica during the following times: MON - FRI 9AM to 12AM EST and SAT - SUN 9AM to 6PM EST. Additionally, you can order online from DoctorMedica twenty-four hours a day, seven days a week.',
				},
			},
			{
				'@type': 'Question',
				name: 'Who can order from DoctorMedica?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: "To order medical supplies from DoctorMedica, you must be an individual or corporation that is licensed to handle the products you order. We do not sell medical products to unlicensed individuals under any circumstances. If you're unsure about the exact licensing requirements, feel free to contact us.",
				},
			},
			{
				'@type': 'Question',
				name: 'Can I order from DoctorMedica if I live outside the United States?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'Regrettably, DoctorMedica only services the US market at this time.',
				},
			},
			{
				'@type': 'Question',
				name: 'How much does shipping cost from DoctorMedica?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'Shipping is complimentary for orders above $500. However, if your order is less than $500, a shipping fee of $50 will apply.',
				},
			},
			{
				'@type': 'Question',
				name: 'How do I reset my password? What if I have trouble logging in to my account?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: "If you encounter issues logging into DoctorMedica, or need to reset your password, you can either contact one of our customer service representatives or click 'Forgot password?' when trying to log in, to safely reset your password.",
				},
			},
			{
				'@type': 'Question',
				name: 'Can someone order from DoctorMedica on my behalf?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: "Yes, a third party such as your medical secretary can place orders on your behalf. However, the licensed individual or corporation must sign an authorization form and send it to us via fax or email. If you're unsure how to proceed, feel free to reach out to our customer service representatives for assistance.",
				},
			},
			{
				'@type': 'Question',
				name: 'How do discounts work on DoctorMedica?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'When ordering wholesale medical supplies from DoctorMedica, discounts can be obtained by purchasing larger quantities. The more you buy, the lower the price. For instance, buying 10 products at a time saves more than buying 5 products. Discount prices can be found on each product page and will be applied to the items in your shopping cart during checkout.',
				},
			},
			{
				'@type': 'Question',
				name: 'Do I need to order a minimum quantity to get wholesale prices?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'There is no minimum quantity requirement for orders, but prices do decrease as the quantity of a single product increases.',
				},
			},
			{
				'@type': 'Question',
				name: 'Which currency is used on the website?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'All prices on the website are listed in US dollars.',
				},
			},
			{
				'@type': 'Question',
				name: 'Do you offer any referral incentives?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'Yes, we do. More information about our Referral Program can be found here.',
				},
			},
			{
				'@type': 'Question',
				name: 'When will I get my order?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'Once your order is processed, we aim to ship your product within the next two business days. Occasionally, due to high demand, we may not have your desired item in stock when you place your order; we will inform you at the time of order placement in such cases. Delivery times vary but usually take between seven to ten days. Upon shipping your order, a tracking number will be provided within twenty-four hours.',
				},
			},
			{
				'@type': 'Question',
				name: 'Which shipping methods can I choose?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'Available shipping methods can vary by region. To discuss your options, please contact one of our customer service representatives.',
				},
			},
			{
				'@type': 'Question',
				name: 'How do I know the products you sell are genuine?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: "We only sell genuine, original products. You will receive your products unadulterated and sealed in the original manufacturer's packaging. Please check the integrity of the packaging upon receipt.",
				},
			},
			{
				'@type': 'Question',
				name: 'What type of packaging do you use to ship my order?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'If the products you order are not temperature sensitive, they are shipped in cushioned, regular cardboard boxes, appropriately sized to the items ordered. If the products are heat-sensitive, we ship them in thermo-regulated packages with reinforced cushioning and ice packs, ensuring their safety for up to ten days while in transit.',
				},
			},
			{
				'@type': 'Question',
				name: 'What if I am interested in a product that is not on your website?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'If you are interested in a product not currently listed on our website, please contact our customer service team via email or phone. We would be glad to check its availability for you.',
				},
			},
			{
				'@type': 'Question',
				name: 'What if I find a cheaper price on medical supplies elsewhere?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'Although we strive to bring you the lowest possible prices on medical injectables and other supplies, if you find a lower price elsewhere, please let us know. If you provide proof of the lower price and the terms are similar to ours, we will do our best to match or beat it.',
				},
			},
			{
				'@type': 'Question',
				name: 'How do the products from DoctorMedica differ from the ones I usually order?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'The only difference between the products you typically order locally and the ones you order online from DoctorMedica is the cosmetic appearance of the packaging, and of course, the cost. Since multinational manufacturers must adjust their packaging to meet the requirements of each distribution country, there may be minor differences in packaging. If you would like to know more about any of the products we sell on DoctorMedica, one of our knowledgeable customer care associates would be happy to assist you.',
				},
			},
			{
				'@type': 'Question',
				name: 'Where can I learn more about DoctorMedica policies?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'To learn more about our policies, please refer to our Privacy Policy and Terms of Service.',
				},
			},
			{
				'@type': 'Question',
				name: 'What are the medical licenses that Doctor Medica recognizes?',
				acceptedAnswer: {
					'@type': 'Answer',
					text: 'To learn more about our policies, please refer to our Privacy Policy and Terms of Service.',
				},
			},
		],
	};

	return (
		<>
			<Layout>
				<Helmet>
					<script type='application/ld+json'>
						{JSON.stringify(FAQSchema, null, 2)}
					</script>
				</Helmet>
				<div className={styles.faq}>
					<div className={styles.privacypolicyslot}>
						<div className={styles.titlebox}>
							<div className={styles.divider} />
							<div className={styles.title}>
								<h1 className={styles.frequentlyAskedQuestions}>
									Frequently Asked Questions
								</h1>
							</div>
						</div>
						<div className={styles.textcolumn}>
							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									What are your customer service hours?
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									You can reach a customer service representative at
									DoctorMedica during the following times: MON - FRI 9AM to 12AM
									EST and SAT - SUN 9AM to 6PM EST. Additionally, you can order
									online from DoctorMedica twenty-four hours a day, seven days a
									week.
								</Text>
							</div>
							<div className={styles.textbody}>
								<div className={styles.textbody}>
									<Text
										fontWeight={700}
										className={styles.whatAreYour}>
										Who can order from DoctorMedica?
									</Text>
								</div>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									To order medical supplies from DoctorMedica, you must be an
									individual or corporation that is licensed to handle the
									products you order. We do not sell medical products to
									unlicensed individuals under any circumstances. If you're
									unsure about the exact licensing requirements, feel free to
									<a
										href='/contact-us'
										style={{ color: '#d28c49' }}>
										{' '}
										contact us
									</a>
									.
								</Text>
							</div>
							<div className={styles.textbody}>
								<div className={styles.textbody}>
									<Text
										fontWeight={700}
										className={styles.whatAreYour}>
										Can I order from DoctorMedica if I live outside the United
										States?
									</Text>
								</div>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									Regrettably, DoctorMedica only services the US market at this
									time.
								</Text>
							</div>
	
							<div className={styles.textbody}>
								<div className={styles.textbody}>
									<Text
										fontWeight={700}
										className={styles.whatAreYour}>
										How much does shipping cost from DoctorMedica?
									</Text>
								</div>
							</div>
							<div className={styles.textbody9}>
								<div className={styles.youCanReach}>
									<p className={styles.shippingCostsDepend}>
										Shipping is complimentary for orders above $500. However, if
										your order is less than $500, a shipping fee of $50 will
										apply.
									</p>
		
								</div>
							</div>
							<div className={styles.textbody}>
								<div className={styles.textbody}>
									<Text
										fontWeight={700}
										className={styles.whatAreYour}>
										How do I reset my password? What if I have trouble logging
										in to my account?
									</Text>
								</div>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									If you encounter issues logging into DoctorMedica, or need to
									reset your password, you can either contact one of our
									customer service representatives or click{' '}
									<a
										href='/forgot-password'
										style={{ color: '#d28c49' }}>
										{' '}
										"Forgot password?"{' '}
									</a>
									when trying to log in, to safely reset your password.
								</Text>
							</div>
							<div className={styles.textbody}>
								<div className={styles.textbody}>
									<Text
										fontWeight={700}
										className={styles.whatAreYour}>
										Can someone order from DoctorMedica on my behalf?
									</Text>
								</div>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									Yes, a third party such as your medical secretary can place
									orders on your behalf. However, the licensed individual or
									corporation must sign an authorization form and send it to us
									via fax or email. If you're unsure how to proceed, feel free
									to reach out to our customer service representatives for
									assistance.
								</Text>
							</div>
							<div className={styles.textbody}>
								<div className={styles.textbody}>
									<Text
										fontWeight={700}
										className={styles.whatAreYour}>
										How do discounts work on DoctorMedica?
									</Text>
								</div>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									When ordering wholesale medical supplies from DoctorMedica,
									discounts can be obtained by purchasing larger quantities. The
									more you buy, the lower the price. For instance, buying 10
									products at a time saves more than buying 5 products. Discount
									prices can be found on each product page and will be applied
									to the items in your shopping cart during checkout.
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									Do I need to order a minimum quantity to get wholesale prices?
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									There is no minimum quantity requirement for orders, but
									prices do decrease as the quantity of a single product
									increases.
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									Which currency is used on the website?
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									All prices on the website are listed in US dollars.
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									Do you offer any referral incentives?
								</Text>
							</div>
							<div className={styles.textbody7}>
								<Text className={styles.youCanReach}>
									Yes, we do. More information about our Referral Program can be
									found {''}
									<a
										className={styles.bulkdoctormedicaco}
										href='/referral-program'
										target='_blank'
										rel='noopener'
										style={{ color: '#d28c49' }}>
										here
									</a>
									.
								</Text>
							</div>

							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									When will I get my order?
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									Once your order is processed, we aim to ship your product
									within the next two business days. Occasionally, due to high
									demand, we may not have your desired item in stock when you
									place your order; we will inform you at the time of order
									placement in such cases. Delivery times vary but usually take
									between seven to ten days. Upon shipping your order, a
									tracking number will be provided within twenty-four hours.
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									Which shipping methods can I choose?
								</Text>
							</div>
							<div className={styles.textbody}>
								<div className={styles.youCanReach}>
									Available shipping methods can vary by region. To discuss your
									options, please contact one of our customer service
									representatives.
								</div>
							</div>
							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									How do I know the products you sell are genuine?
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									We only sell genuine, original products. You will receive your
									products unadulterated and sealed in the original
									manufacturer's packaging. Please check the integrity of the
									packaging upon receipt.
								</Text>
							</div>
	
							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									What type of packaging do you use to ship my order?
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									If the products you order are not temperature sensitive, they
									are shipped in cushioned, regular cardboard boxes,
									appropriately sized to the items ordered. If the products are
									heat-sensitive, we ship them in thermo-regulated packages with
									reinforced cushioning and ice packs, ensuring their safety for
									up to ten days while in transit.
								</Text>
							</div>

							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									What if I am interested in a product that is not on your
									website?
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									If you are interested in a product not currently listed on our
									website, please contact our customer service team via email or
									phone. We would be glad to check its availability for you.
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									What if I find a cheaper price on medical supplies elsewhere?
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									Although we strive to bring you the lowest possible prices on
									medical injectables and other supplies, if you find a lower
									price elsewhere, please let us know. If you provide proof of
									the lower price and the terms are similar to ours, we will do
									our best to match or beat it.
								</Text>
							</div>

							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									How do the products from DoctorMedica differ from the ones I
									usually order?
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text className={styles.youCanReach}>
									The only difference between the products you typically order
									locally and the ones you order online from DoctorMedica is the
									cosmetic appearance of the packaging, and of course, the cost.
									Since multinational manufacturers must adjust their packaging
									to meet the requirements of each distribution country, there
									may be minor differences in packaging. If you would like to
									know more about any of the products we sell on DoctorMedica,
									one of our knowledgeable customer care associates would be
									happy to assist you.
								</Text>
							</div>
							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									Where can I learn more about DoctorMedica policies?
								</Text>
							</div>
							<div className={styles.textbody7}>
								<Text className={styles.youCanReach}>
									To learn more about our policies, please refer to our
									<a
										className={styles.bulkdoctormedicaco}
										href='/privacy-policy'
										target='_blank'
										rel='noopener'
										style={{ color: '#d28c49' }}>
										{''} Privacy Policy {''}
									</a>
									and
									<a
										className={styles.bulkdoctormedicaco}
										href='/use-policy'
										target='_blank'
										rel='noopener'
										style={{ color: '#d28c49' }}>
										{''} Terms of Service
									</a>
									.
								</Text>
							</div>

							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									What are the medical licenses that Doctor Medica recognizes?
								</Text>
							</div>
							<div className={styles.textbody7}>
								<Text className={styles.youCanReach}>
									The acceptable/valid medical licenses are:
								</Text>
								<ul>
									<li>Medical Doctor</li>
									<li>Osteopathic Doctor</li>
									<li>Doctor of Medical Dentistry</li>
									<li>Doctor of Dental Surgery</li>
									<li>Doctor of Podiatric Medicine</li>
									<li>Physician Assistant</li>
									<li>Nurse Practitioner</li>
									<li>Advanced Registered Nurse Practitioner</li>
									<li>Advanced Practice Registered Nurse</li>
									<li>Family Nurse Practitioner</li>
									<li>Advanced Practice Nurse</li>
									<li>Doctor of Veterinary Medicine</li>
								</ul>
							</div>

							<div className={styles.textbody}>
								<Text
									fontWeight={700}
									className={styles.whatAreYour}>
									Are all products sold by your company guaranteed to be
									genuine?
								</Text>
							</div>
							<div className={styles.textbody7}>
								<Text className={styles.youCanReach}>
									Doctor Medica exclusively offers authentic products with
									original LOT numbers. Feel free to{' '}
									<a
										href='/contact-us'
										rel='noopener'
										style={{ color: '#d28c49' }}>
										{' '}
										contact us
									</a>{' '}
									if you need the LOT number confirmation.
								</Text>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
};

export const Head = () => (
	<SEO
		title='Frequently Asked Questions | DoctorMedica.co'
		description='As a healthcare professional, where can you learn how to order orthopedic injectables and dermal fillers online? Read the Doctor Medica FAQ.'
	/>
);

export default FAQ;
