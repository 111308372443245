// extracted by mini-css-extract-plugin
export var bulkdoctormedicaco = "faq-module--bulkdoctormedicaco--b7a95";
export var divider = "faq-module--divider--654ba";
export var faq = "faq-module--faq--0b12d";
export var frequentlyAskedQuestions = "faq-module--frequentlyAskedQuestions--f8609";
export var privacypolicyslot = "faq-module--privacypolicyslot--b23f2";
export var shippingCostsDepend = "faq-module--shippingCostsDepend--1599b";
export var textbody = "faq-module--textbody--95030";
export var textbody7 = "faq-module--textbody7--fac29";
export var textbody9 = "faq-module--textbody9--e07be";
export var textcolumn = "faq-module--textcolumn--7c256";
export var title = "faq-module--title--a4570";
export var titlebox = "faq-module--titlebox--29e99";
export var uk10But = "faq-module--uk10But--9d3bb";
export var uk10ButShippingIsFree = "faq-module--uk10ButShippingIsFree--a3032";
export var whatAreYour = "faq-module--whatAreYour--2c163";
export var youCanReach = "faq-module--youCanReach--0cb99";